import { useLocalFormState } from '@gnist/design-system';
import {
    noValidation,
    required,
} from '@gnist/design-system/utilities/validation';
import {
    AdditionalInfoResourceGroup,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { useEffect, useRef } from 'react';
import { getFastTrackServices, useFastTrack } from '../../lib/useFastTrack';
import { Extent } from './features/glassDamage/glassDamageFormState';
import { RecommendedServiceFormState } from './features/recommendedServices/recommendedServiceFormState';
import { useSectionValidators } from './useSectionValidators';

export type StdServicesForm = {
    selectedIds: string[];
    additionalInfo: {
        adapterId: string;
        serviceId: string;
        comment?: string;
        resourceGroup?: AdditionalInfoResourceGroup;
    }[];
    glassDamageExtent: Extent;
    glassDamageNearEdge: boolean;
    glassDamageIncidentDate: string;
    glassDamageIncidentLocation: string;
    recommendedService: RecommendedServiceFormState;
};

export function useStandardServicesForm(
    validators: ReturnType<typeof useSectionValidators>,
    idsFromURL: string[],
    availableServices: ServiceViewModel[] | undefined
) {
    const formState = useLocalFormState<StdServicesForm>(
        {
            selectedIds: [],
            glassDamageExtent: 'large',
            glassDamageNearEdge: false,
            glassDamageIncidentLocation: '',
            glassDamageIncidentDate: '',
            recommendedService: { mileage: '', open: false },
            additionalInfo: [],
        },
        {
            selectedIds: required(validators.validServiceIds),
            glassDamageExtent: noValidation(),
            glassDamageNearEdge: noValidation(),
            additionalInfo: validators.validServiceAdditionalInfo,
            recommendedService: validators.validRecommendedServices,
            ...validators.glassDamage,
        },
        {
            showNecessityOn: 'optional',
        }
    );

    const setSelectedIds = formState.setValue('selectedIds');
    const urlRef = useRef(false);

    // Set selected services from url
    useSetServiceIds(
        setServiceIds,
        idsFromURL,
        availableServices,
        setSelectedIds,
        urlRef
    );

    const fastTrackRef = useRef(false);
    const fastTrack = useFastTrack();
    const fastTrackServices = getFastTrackServices(
        availableServices ?? [],
        fastTrack.fastTrack
    );

    // Set selected services for fasttrack
    useSetServiceIds(
        setServiceIds,
        fastTrackServices?.preselected,
        availableServices,
        setSelectedIds,
        fastTrackRef
    );

    return formState;
}

export function useSetServiceIds(
    fn: typeof setServiceIds,
    selectedServiceIds: string[] | undefined,
    availableServices: ServiceViewModel[] | undefined,
    setSelectedIds: (value: string[]) => void,
    ref: React.MutableRefObject<boolean>
) {
    useEffect(() => {
        if (
            availableServices &&
            !ref.current &&
            selectedServiceIds &&
            selectedServiceIds.length > 0
        ) {
            fn(selectedServiceIds, availableServices, setSelectedIds, ref);
        }
    }, [availableServices, fn, selectedServiceIds, ref, setSelectedIds]);
}

export function setServiceIds(
    selectedServiceIds: string[],
    availableServices: ServiceViewModel[],
    setSelectedIds: (value: string[]) => void,
    ref: React.MutableRefObject<boolean>
) {
    const selectedIds = selectedServiceIds.filter((id) =>
        availableServices.some((service) => service.id === id)
    );
    setSelectedIds(selectedIds);
    ref.current = true;
}
