import { ServiceViewModel } from 'external-apis/src/types/port';
import { useStandardServicesForm } from '../useSectionForm';
import { CommentField } from './CommentField';
import { ResourceGroups } from './ResourceGroups';
import { setAdditionalInfo } from './AdditionalInfoFormState';
import { existingInfo, IncludesInfoId } from './AdditionalInfoHelpers';
import { useFlag } from 'feature-toggle';

type AdditionalServiceInfoProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    service: ServiceViewModel;
};

export function AdditionalServiceInfo({
    serviceForm,
    service,
}: AdditionalServiceInfoProps) {
    const info = service.additionalInfo;
    const noAdditionalInfo = !info || info.length === 0;
    if (noAdditionalInfo) {
        return null;
    }
    return (
        <>
            {info.map((x, i) => (
                <AdditionalInfoContent
                    key={`${x.infoType}=${i}`}
                    serviceForm={serviceForm}
                    service={service}
                    info={x}
                />
            ))}
        </>
    );
}

type AdditionalInfoContentProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    info: NonNullable<ServiceViewModel['additionalInfo']>[number];
    service: ServiceViewModel;
};

function AdditionalInfoContent({
    service,
    serviceForm,
    info,
}: AdditionalInfoContentProps) {
    const inputProps = serviceForm.inputProps;
    const selectedServices = inputProps('selectedIds').value;
    const selectedValues = inputProps('additionalInfo').value;
    const setValues = inputProps('additionalInfo').setValue;
    const validity = inputProps('additionalInfo').validity;
    const serviceInfoId: IncludesInfoId = {
        adapterId: service.adapterId,
        serviceId: service.id,
    };
    const showResourceGroups = useFlag('enable-show-forkontroll');

    if (info.infoType === 'Comment' && selectedServices.includes(service.id)) {
        const setComment = setAdditionalInfo({
            service: serviceInfoId,
            field: 'comment',
            selectedValues,
            setValues,
        });
        const comment =
            selectedValues.find(existingInfo(serviceInfoId))?.comment ?? '';
        return (
            <CommentField
                comment={comment}
                setComment={setComment}
                validity={validity}
            />
        );
    }

    if (
        info.infoType === 'ResourceGroup' &&
        showResourceGroups &&
        selectedServices.includes(service.id)
    ) {
        const setResourceGroup = setAdditionalInfo({
            service: serviceInfoId,
            field: 'resourceGroup',
            selectedValues,
            setValues,
        });
        const selectedGroup = selectedValues.find(
            existingInfo(serviceInfoId)
        )?.resourceGroup;
        return (
            <ResourceGroups
                groups={info.resourceGroups}
                setResourceGroup={setResourceGroup}
                selectedGroup={selectedGroup}
                validity={validity}
            />
        );
    }
}
