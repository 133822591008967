import { AdditionalInfoResourceGroup } from 'external-apis/src/types/port';
import {
    existingInfo,
    IncludesInfoId,
    Info,
    similarInfo,
} from './AdditionalInfoHelpers';
import { fakeSetState, fakeState } from '../../testData';

type Field = keyof Omit<Info, 'adapterId' | 'serviceId'>;

type SetAdditionalInfo = {
    service: IncludesInfoId;
    field: Field;
    selectedValues: Info[];
    setValues: (x: Info[]) => void;
};

// these overloads are essential for tsc to infer correct return types
export function setAdditionalInfo(
    props: SetAdditionalInfo & { field: 'comment' }
): (comment: string) => void;
export function setAdditionalInfo(
    props: SetAdditionalInfo & { field: 'resourceGroup' }
): (resourceGroup: AdditionalInfoResourceGroup) => void;

export function setAdditionalInfo({
    service,
    field,
    selectedValues,
    setValues,
}: SetAdditionalInfo) {
    const infoToUpdate = selectedValues.find(existingInfo(service));
    const infosNotToUpdate = selectedValues.filter(
        (x) => !similarInfo(x, service)
    );
    switch (field) {
        case 'resourceGroup': {
            return function (resourceGroup: AdditionalInfoResourceGroup) {
                setValues([
                    ...infosNotToUpdate,
                    upsertInfo(infoToUpdate, service, resourceGroup, field),
                ]);
            };
        }
        case 'comment': {
            return function (comment: string) {
                setValues([
                    ...infosNotToUpdate,
                    upsertInfo(infoToUpdate, service, comment, field),
                ]);
            };
        }
    }
}

if (import.meta.vitest) {
    const { describe, expect, it } = import.meta.vitest;
    describe('Set additional info', function () {
        const service: IncludesInfoId = {
            adapterId: 'x',
            serviceId: 'y',
        };
        it.concurrent('set comment', function () {
            const state = fakeState<Info[]>([]);
            const setState = fakeSetState(state);
            const comment = 'This is a comment';
            setAdditionalInfo({
                service,
                selectedValues: [],
                field: 'comment',
                setValues: setState,
            })(comment);
            const expected: Info[] = [
                {
                    ...service,
                    comment,
                },
            ];
            expect(state.value).toEqual(expected);
        });
        it.concurrent('set resource group', function () {
            const state = fakeState<Info[]>([]);
            const setState = fakeSetState(state);
            const resourceGroup: AdditionalInfoResourceGroup = 'Noise';
            setAdditionalInfo({
                service,
                selectedValues: [],
                field: 'resourceGroup',
                setValues: setState,
            })(resourceGroup);
            const expected: Info[] = [
                {
                    ...service,
                    resourceGroup,
                },
            ];
            expect(state.value).toEqual(expected);
        });
    });
}

function upsertInfo<T>(
    infoToUpdate: Info | undefined,
    element: IncludesInfoId,
    value: T,
    field: Field
): Info {
    if (!infoToUpdate) {
        return {
            adapterId: element.adapterId,
            serviceId: element.serviceId,
            [field]: value,
        };
    }
    return { ...infoToUpdate, [field]: value };
}

if (import.meta.vitest) {
    const { describe, expect, it } = import.meta.vitest;
    describe.each<{
        valueField: string;
        updatedValueField: string;
        fieldUnderTest: Field;
        differentField: Field;
        valueDifferentField: string;
    }>([
        {
            valueField: 'Noise',
            updatedValueField: 'HatchThatWontOpen',
            fieldUnderTest: 'resourceGroup',
            differentField: 'comment',
            valueDifferentField: 'This is expected to remain',
        },
        {
            valueField: 'This is a comment',
            updatedValueField: 'This is an updated comment',
            fieldUnderTest: 'comment',
            differentField: 'resourceGroup',
            valueDifferentField: 'Noise',
        },
    ])(
        'Upsert additional info field',
        function ({
            valueField,
            updatedValueField,
            fieldUnderTest,
            differentField,
            valueDifferentField,
        }) {
            const service: IncludesInfoId = { adapterId: 'x', serviceId: 'y' };
            it.concurrent(
                `insert ${fieldUnderTest} with existing info`,
                function () {
                    const info: Info = {
                        ...service,
                        [differentField]: valueDifferentField,
                    };
                    const actual = upsertInfo(
                        info,
                        service,
                        valueField,
                        fieldUnderTest
                    );
                    const expected: Info = {
                        ...info,
                        [fieldUnderTest]: valueField,
                    };
                    expect(actual).toEqual(expected);
                }
            );
            it.concurrent(
                `insert ${fieldUnderTest} without existing info`,
                function () {
                    const actual = upsertInfo(
                        undefined,
                        service,
                        valueField,
                        fieldUnderTest
                    );
                    const expected: Info = {
                        ...service,
                        [fieldUnderTest]: valueField,
                    };
                    expect(actual).toEqual(expected);
                }
            );
            it.concurrent(`update ${fieldUnderTest}`, function () {
                const info: Info = {
                    ...service,
                    [fieldUnderTest]: valueField,
                };
                const actual = upsertInfo(
                    info,
                    service,
                    updatedValueField,
                    fieldUnderTest
                );
                const expected: Info = {
                    ...info,
                    [fieldUnderTest]: updatedValueField,
                };
                expect(actual).toEqual(expected);
            });
        }
    );
}
